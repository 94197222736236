import { BASE_URL } from "../helpers/constants";
import {
  ADD_DISCOUNT_CODE,
  ADD_PERCENTAGE_REFERRAL_CODE,
  ADD_REFERRAL_CODE,
  REFERRAL_ENABLE_DISABLE_DELETE,
  GET_DISCOUNT_API_LIST,
  GET_SINGLE_USER_REFERRAL_HISTORY,
  DISCOUNT_ENABLE_DISABLE_DELETE,
  DISCOUNT_UPDATE_PERCENTAGE,
} from "./apiSchema";
import { fetch } from "./fetch";

/**
 * API to add referral code
 * @param {object} data - The object which contains referral code
 * @returns {Promise<object>} - The response data
 * @throws {Error} - Throws an error if there is any issue
 */
const addReferralCodeApi = async (data: ADD_REFERRAL_CODE): Promise<object> => {
  try {
    console.log("data api:",data)
    const response = await fetch(
      "post",
      `${BASE_URL}/user/add-referral-code`,
      data,
      {},
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addOrUpdateReferralPercentage = async (
  data: ADD_PERCENTAGE_REFERRAL_CODE
): Promise<object> => {
  try {
    const response = await fetch(
      "post",
      `${BASE_URL}/referral/update`,
      data,
      {},
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const getSingleUserReferralHistoryApi = async (data:GET_SINGLE_USER_REFERRAL_HISTORY):Promise<object>=>{
    try {
        const response = await fetch('get',`${BASE_URL}/referral/history`,{},{},data)
        return response.data
    } catch (error) {
        throw error
    }
}



const getListReferralApi = async (data:GET_SINGLE_USER_REFERRAL_HISTORY):Promise<object>=>{
    try {
        const response = await fetch('get',`${BASE_URL}/referral/users-list`,{},{},data)
        return response.data
    } catch (error) {
        throw error
    }
}





const enableDisableDeleteReferralApi = async (data:REFERRAL_ENABLE_DISABLE_DELETE):Promise<object>=>{
    try {
        const response = await fetch('post',`${BASE_URL}/referral/enable-disable-delete`,data,{},{})
        return response.data
    } catch (error) {
        throw error
    }
}




// 
const getListDiscountApi = async (data:GET_DISCOUNT_API_LIST):Promise<object>=>{
  try {
      const response = await fetch('get',`${BASE_URL}/discount/list`,{},{},data)
      return response.data
  } catch (error) {
      throw error
  }
}



const addDiscountCodeApi = async (data: ADD_DISCOUNT_CODE): Promise<object> => {
  try {
    console.log("data api:",data)
    const response = await fetch(
      "post",
      `${BASE_URL}/discount/create`,
      data,
      {},
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



const enableDisableDeleteDiscountApi = async (data:DISCOUNT_ENABLE_DISABLE_DELETE): Promise<object> => {
  try {
    const response = await fetch(
      "post",
      `${BASE_URL}/discount/enable-disable-delete`,
      data,
      {},
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}



const discountUpdatePercentageApi = async (data:DISCOUNT_UPDATE_PERCENTAGE):Promise<object>=>{
  try {
    const response = await fetch('post',`${BASE_URL}/discount/update`,data,{},{})
    return response.data
  } catch (error) {
    throw(error)
  }
}



export const ApiServices = {
  addReferralCodeApi,
  addOrUpdateReferralPercentage,
  getSingleUserReferralHistoryApi,
  getListReferralApi,
  enableDisableDeleteReferralApi,
  getListDiscountApi,
  addDiscountCodeApi,
  enableDisableDeleteDiscountApi,
  discountUpdatePercentageApi
};
