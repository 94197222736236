import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import OpenImage from "../components/ImageUrl";
import Dashboard from "../pages/Dashboard/pages/Dashboard";
import { AuthProvider } from "../pages/Dashboard/auth/AuthContext";
import Login from "../pages/Dashboard/pages/Login";
import ProtectedRoute from "../pages/Dashboard/auth/ProtectedRoute";
import Referral from "../pages/Dashboard/pages/Referral";
import ReferralDetail from "../pages/Dashboard/pages/ReferralDetail";
import Discount from "../pages/Dashboard/pages/Discount";
import DiscountDetail from "../pages/Dashboard/pages/DiscountDetail";
const Home = React.lazy(() => import("../pages/Home"));

const PublicRoutes: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Vbot-Chart" element={<OpenImage />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/referral"
                element={
                  <ProtectedRoute>
                    <Referral />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard/referral/detail"
                element={
                  <ProtectedRoute>
                    <ReferralDetail />
                  </ProtectedRoute>
                }
              />
               <Route
                path="/dashboard/discount"
                element={
                  <ProtectedRoute>
                    <Discount />
                  </ProtectedRoute>
                }
              />   

               <Route
                path="/dashboard/discount/detail"
                element={
                  <ProtectedRoute>
                    <DiscountDetail />
                  </ProtectedRoute>
                }
              />                          
            </Route>
          </Routes>
        </React.Suspense>
      </Router>
    </AuthProvider>
  );
};

export default PublicRoutes;
