import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // If using React Router

const OpenImage = () => {
  const navigate = useNavigate(); // Hook for navigation (React Router)

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Escape") {
        navigate("/"); // Redirect to homepage on 'Esc' key press
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <>
      {/* Inline CSS */}
      <style>
        {`
          .future_of_robotics, .robotics_footer_portion {
            display: none;
          }
        `}
      </style>

      {/* Image */}
      <a href="/">
        <img
          loading="lazy"
          src="/images/Vbot-Chart-full.webp"
          alt="Vbot Chart"
          style={{ width: "100%", maxWidth: "100%" }}
        />
      </a>
    </>
  );
};

export default OpenImage;
