import React from "react";
import { Modal } from "react-bootstrap";


interface ConfirmationPopUpProps {
  showConfirmation: boolean;
  setShowConfirmation: (showModal: boolean) => void;
  setIsConfirm: (isConfirm: boolean) => void;
  handleChange?: (args?: object) => Promise<void>;
  handleConfirm?: () => Promise<void>;
}

const ConfirmationPopUp: React.FC<ConfirmationPopUpProps> = ({
  showConfirmation,
  setShowConfirmation,
  setIsConfirm,
}) => {
  return (
    <div>
      <Modal
        size="lg"
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          Are your sure you want to delete
        </Modal.Header>
        <Modal.Body className="discountModal">
          <button
            type="button"
            className="btn btn-primary mb-2 me-2"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary mb-2"
            onClick={() => setIsConfirm(true)}
          >
            Confirm
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmationPopUp;
