import { AnyARecord } from "node:dns";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";


interface AddReferralPercentageModelProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setIsCalledApi: Dispatch<SetStateAction<boolean>>;
  isCalledApi: boolean;
  setPercentageAmount: (amount: number) => void;
  handleAddPercentage: () => Promise<void>;
  percentageAmount: number;
  userDetails: any;
  isDiscount?: boolean;
  setMaxUsed?: (maxUsed: number) => void;
  maxUsed?: number;
  handleChangeMaxUsedInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  label: string;
  handleChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddPercentageModel: React.FC<AddReferralPercentageModelProps> = ({
  showModal,
  setShowModal,
  userDetails,
  handleAddPercentage,
  setIsCalledApi,
  isCalledApi,
  setPercentageAmount,
  percentageAmount,
  isDiscount,
  maxUsed,
  title,
  label,
  ...props
}) => {
 
  useEffect(()=>{
    setPercentageAmount( userDetails?.referral_percentage ? userDetails?.referral_percentage :  userDetails?.discount_percentage )
    props?.setMaxUsed?.(userDetails?.discount_max_used_count)
  },[])


  /**
   * Handles the input change for the referral percentage input field in the add referral percentage modal.
   * If the input value is a valid number with at most 2 decimal places, then it updates the referral percentage state with the new value.
   * If the input value is not a valid number, then it doesn't update the referral percentage state.
   * @param {React.ChangeEvent<HTMLInputElement>} e The input event.
   */
  const percentageChangeHandler = (e: any) => {
    if (/^\d*(\.\d{0,2})?$/.test(e.target.value)) {
      const newValue = parseFloat(e.target.value);
      setPercentageAmount(Number(newValue));
      setIsCalledApi(false)
    }
  };

  

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body className="discountModal">
        <form className="form-inline">
          <div className="form-group">
            <label htmlFor="referral">{label} (%)</label>
            <input
              name="referral"
              type="number"
              className="form-control"
              value={ percentageAmount }
              onChange={(e) => percentageChangeHandler(e)}
            />
            {isDiscount && (
              <>
                <label htmlFor="Discount">{"Max Used"}</label>

                <input
                  type="text"
                  value={maxUsed}
                  className="form-control"
                  onChange={(e) => props.handleChangeMaxUsedInput?.(e)}
                />
              </>
            )}
            <button
              type="button"
              className="btn btn-primary mb-2"
              onClick={() =>{ setIsCalledApi(true);
                handleAddPercentage()}}
              disabled={isCalledApi === true}
            >
              Confirm
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(AddPercentageModel);
