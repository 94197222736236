import React from "react";
import "./index.scss";

const Arrow = () => {
  return (
    <div className="arrowIcon">
      <img src="/images/arrow-left.svg" alt="icon" className="" />
    </div>
  );
};

export default Arrow;
