// src/components/Layout.tsx
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import PreLoader from "../PreLoader";
import { Helmet } from "react-helmet";
import PreloadImages from "./PreloadImages";

const Layout: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          setTimeout(() => {
            targetElement.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    };

    scrollToHash();

    window.addEventListener("hashchange", scrollToHash);
    return () => {
      window.removeEventListener("hashchange", scrollToHash);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>VBot</title>
        <meta
          name="description"
          content="VBot- The Best Solana Ecosystem Volume Booster."
        />
      </Helmet>
      {/* <PreLoader isLoading={isLoading} /> */}
      <PreloadImages />
      <Outlet />
    </>
  );
};

export default Layout;
