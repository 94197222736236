import React from "react";
import Arrow from "./Arrow";
interface props {
  link?: string;
  classes?: string;
  text?: string;
  target?: string;
  iconClass?: string;
  icon?: string;
  onClickHanlder?: any;
}
const Button: React.FC<props> = ({
  link,
  classes,
  text,
  target,
  iconClass,
  onClickHanlder,
  icon
}) => {
  return (
    <>
      <a
        className={`mainBtn ${classes}`}
        href={link}
        target={target}
        onClick={onClickHanlder}
      >
        {icon && (
          <img src={icon} alt="icon" className="customicon"/>
        )}
        {text}
        {!icon && <Arrow />}
      </a>
    </>
  );
};

export default Button;
