import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from "react-bootstrap/Modal";

interface AddReferralModelProps {
  title: string;
  label: string;
  showAddModal: boolean;
  setShowAddModal: (showModal: boolean) => void;
  handleConfrimApi: () => Promise<void>;
  setIsCalledApi: Dispatch<SetStateAction<boolean>>;
  isCalledApi: boolean;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDiscount?: boolean;
  handleChangeMaxUsedInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addressError?: boolean;
  addressErrorMessage?: string;
}

const AddReferralModel: React.FC<AddReferralModelProps> = ({
  title,
  label,
  showAddModal,
  setShowAddModal,
  handleConfrimApi,
  setIsCalledApi,
  isCalledApi,
  handleChangeInput,
  isDiscount,
  addressError,
  addressErrorMessage,
  ...props
}) => {
  return (
    <Modal
      size="lg"
      show={showAddModal}
      onHide={() => setShowAddModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body className="discountModal">
        <form className="form-inline">
          <div className="form-group">
            <label htmlFor="Discount">{label}</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => handleChangeInput(e)}
            />
            {addressErrorMessage && (
              <p className="text-danger">{addressErrorMessage}</p>
            )}
            {isDiscount && (
              <>
                <label htmlFor="Discount">{"Max Used"}</label>

                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => props.handleChangeMaxUsedInput?.(e)}
                />
              </>
            )}

            <button
              type="button"
              className="btn btn-primary mb-2"
              onClick={() => {
                handleConfrimApi();
                setIsCalledApi(true);
              }}
              disabled={addressError == true || isCalledApi === true}
            >
              Confirm
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(AddReferralModel);
