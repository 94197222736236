import React from "react";
import SideLayout from "../pages/SideLayout";
import Button from "../../../components/Button";
import { useNavigate } from 'react-router-dom';
function DiscountDetail() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  return (
    <SideLayout>
      <div className="sectionMain">
        <div className="sectionHeader">
          <div className="topSection">
           <Button text="Back" icon ="/images/Back.svg" classes="backIcon" onClickHanlder={handleBack}></Button>
          <h2>Referral Details</h2>           
          </div>
        </div>
        <div className="sectionInnerMain">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">S No.</th>
                <th scope="col">Discount Code</th>
                <th scope="col">Percentage</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
                <tbody>
                  <tr>
                    <td scope="row">1</td>
                    <td>ABCD</td>
                    <td>10%</td>
                    <td>100</td>
                  </tr>
                </tbody>
          </table>
        </div>
      </div>
    </SideLayout>
  );
}

export default DiscountDetail;
