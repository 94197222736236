import React from "react";
import SideLayout from "../pages/SideLayout";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/Button";
import { useNavigate } from 'react-router-dom';
function ReferralDetail() {
  const navigate = useNavigate();
  const signleUserReferral = useSelector((state: any) => state?.referral?.singleUserReferralHistory)

  console.log("signleUserReferral",signleUserReferral)

  const handleBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  return (
    <SideLayout>
      <div className="sectionMain">
        <div className="sectionHeader">
          <div className="topSection">
           <Button text="Back" icon ="/images/Back.svg" classes="backIcon" onClickHanlder={handleBack}></Button>
          <h2>Referral Details</h2>           
          </div>
        </div>
        <div className="sectionInnerMain">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">S No.</th>
                <th scope="col">Referred By</th>
                <th scope="col">Referred To</th>
                <th scope="col">Percentage</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            {signleUserReferral.length ? (
              signleUserReferral.map((item: any, index: number) => (
                <tbody>
                  <tr key={index}>
                    <td scope="row">{index + 1}</td>
                    <td>{item.address}</td>
                    <td>{item.referredBy}</td>
                    <td>{item.referredTo}</td>
                    <td>{item.percentage}</td>
                    <td>{item.amount}</td>
                  </tr>
                </tbody>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{textAlign:"center"}}>No data found</td>
              </tr>
            )}
          </table>
        </div>
      </div>
    </SideLayout>
  );
}

export default ReferralDetail;
