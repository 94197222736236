import React, { useEffect, useState } from "react";
import SideLayout from "../pages/SideLayout";
import Button from "../../../components/Button";
import { ApiServices } from "../../../services/api.service";
import AddReferralModel from "../../../components/Modals/AddReferralCodeModel";
import AddPercentageModel from "../../../components/Modals/ReferralModel";
import "react-tooltip/dist/react-tooltip.css";
import {
  ADD_REFERRAL_CODE,
  ApiResponse,
  REFERRAL_ENABLE_DISABLE_DELETE,
  GET_SINGLE_USER_REFERRAL_HISTORY,
  ADD_PERCENTAGE_REFERRAL_CODE,
} from "../../../services/apiSchema";
import { useDispatch } from "react-redux";
import { getSingleUserReferralHistorySlice } from "../../../redux/slice/dashboard.slice";
import {
  copyToClipboard,
  isValidSolanaAddress,
} from "../../../helpers/utilites";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import { toastMsg } from "../../../components/Toast";
import { ACCESS_VBOT_TELEGRAM_LINK } from "../../../helpers/constants";
import ConfirmationPopUp from "../../../components/Modals/ConfirmationPopUp";
import PaginationCommon from "../../../components/PaginationCommon";



const Referral: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const dispatch = useDispatch();
  const [userAddres, setUserAddress] = useState("");
  const [page, setPage] = useState(1);
  const [referralList, setReferralList] = useState({
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    list: [],
  });
  const [search, setSearch] = useState<any>("");
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isCalledApi, setIsCalledApi] = useState(false);
  const [addressError, setAddressError] = useState<any>(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState<any>("");
  const [percentageAmount, setPercentageAmount] = useState(0);
  const LIMIT = 10




  /**
   * This function is used to generate referral code based on user address and it is called when add button is clicked
   * @returns {Promise<void>}
   */
  const handleAddReferralCode = async (): Promise<void> => {
    try {
      const data: ADD_REFERRAL_CODE = {
        userAddress: userAddres,
      };
      const response = (await ApiServices.addReferralCodeApi(
        data
      )) as ApiResponse;
      if (response.status === 200) {
        toastMsg.success(response?.message);
        setIsCalledApi(false);
        getReferralListFunction();
        setShowAddModal(false);
      }
    } catch (error: any) {
      setIsCalledApi(false);
      toastMsg.error(error?.data?.message);
    }
  };

  /**
   * This function is used to fetch referral history of the user based on its wallet address
   * @param {string} userWalletAddress - The wallet address of the user
   * @returns {Promise<void>}
   */
  const getSignleUserReferralHistoryFunction = async (
    userWalletAddress: any
  ): Promise<void> => {
    try {
      const data: GET_SINGLE_USER_REFERRAL_HISTORY = {
        page: 1,
        limit: LIMIT,
        userAddress: userWalletAddress, // required
        orderBy: "ASC", // optional
      };
      const response = (await ApiServices.getSingleUserReferralHistoryApi(
        data
      )) as ApiResponse;
      if (response.status == 200) {
        dispatch(getSingleUserReferralHistorySlice(response?.data?.list));
        navigate("/dashboard/referral/detail");
      }
    } catch (error: any) {
      toastMsg.error(error?.data?.message);
    }
  };


  /**
 * Fetches the list of referrals from the server and updates the state with the response data.
 * If a search term is provided, it filters the referrals by the user's address.
 * On successful retrieval, updates the referral list state with pagination details.
 * Displays an error toast message if the API call fails.
 */
  const getReferralListFunction = async (): Promise<void> => {
    try {
      const data: GET_SINGLE_USER_REFERRAL_HISTORY = {
        page: page,
        limit: LIMIT,
        orderBy: "ASC", // optional
      };
      if (search) {
        data.userAddress = search;
      }
      const response = (await ApiServices.getListReferralApi(
        data
      )) as ApiResponse;
      if (response.status == 200) {
        setReferralList({
          currentPage: response?.data?.currentPage,
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          list: response?.data?.list,
        });
      }
    } catch (error: any) {
      toastMsg.error(error?.data?.message);
    }
  };

  useEffect(() => {
    // Avoiding multiple call while typing for search
    let timer: any;
    if (search) {
      if (isValidSolanaAddress(search)) {
        timer = setTimeout(() => {
          getReferralListFunction();
        }, 1000);
      }
    } else {
      getReferralListFunction();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [page, search]);

  /**
   * Enable/Disable or Delete referral by user wallet address.
   * If `isConfirm` is true, it deletes the referral.
   * If `isConfirm` is false, it enables/disables the referral.
   * @param {object} item - Referral object
   * @returns {Promise<void>}
   */
  const handleEnableDisableDeleteReferralFunction = async (
    item: any
  ): Promise<void> => {
    try {
      const data: REFERRAL_ENABLE_DISABLE_DELETE = {
        userUid: item?.user_uid,
        userAddress: item.user_wallet_address,
        isEnable: item.active == 1 ? "false" : item.active == 0 ? "true" : "", // OPTIONAL
        // isDelete: true // OPTIONAL
      };
      if (isConfirm === true) {
        data.isDelete = "true"; // OPTIONAL
      }
      const response = (await ApiServices.enableDisableDeleteReferralApi(
        data
      )) as ApiResponse;
      if (response?.status === 200) {
        setIsConfirm(false);
        setShowConfirmation(false);
        setIsCalledApi(false);
        toastMsg.success(response?.message);
        getReferralListFunction();
      }
    } catch (error: any) {
      setIsCalledApi(false);
      toastMsg.error(error?.data?.message);
    }
  };

  useEffect(() => {
    if (isConfirm === true) {
      handleEnableDisableDeleteReferralFunction(userDetails);
    }
  }, [isConfirm]);

  /**
   * This function is used to handle search input, it sets the search state and
   * also checks if the input is valid solana address or not, if not then it shows
   * an error message.
   * @param {object} e - event object
   */
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPage(1)
    if (e.target.value !== "" && !isValidSolanaAddress(e.target.value)) {
      toastMsg.error("Invalid wallet address.");
    }
  };



  /**
   * Add or update referral percentage.
   * If `percentageAmount` is valid number, then it updates the referral percentage.
   * If `percentageAmount` is not valid number, then it adds the referral percentage.
   * @returns {Promise<void>}
   */
const handleAddPercentage = async (): Promise<void> => {
    try {

      const data: ADD_PERCENTAGE_REFERRAL_CODE = {
        userAddress: userDetails?.user_wallet_address,
        userUid: userDetails?.user_uid,
        value: !Number.isNaN(percentageAmount)  ? percentageAmount:userDetails.referral_percentage , // OPTIONAL
      };

      const response = (await ApiServices.addOrUpdateReferralPercentage(
        data
      )) as ApiResponse;
      if (response.status === 200) {
        getReferralListFunction();
        setShowModal(false);
        setIsCalledApi(false)
        toastMsg.success(response?.message);
      }
    } catch (error: any) {
      setIsCalledApi(false)
      toastMsg.error(error?.data?.message);
    }
  };


  // ::::::::: handle paganition change :::::::::
  const handleChange = (e: any) => {
    setPage(e.selected + 1);
  };


/**
 * Handles input change for the user address field.
 * Sets the user address, updates API call status, and validates the address.
 * If the address is invalid, displays an error message.
 * 
 * @param {object} e - The event object from the input field.
 */

  const handleAddressInput = (e: any) => {
      setUserAddress(e.target.value);
      setIsCalledApi(false);
      setAddressError(!isValidSolanaAddress(e.target.value));
      if (e.target.value !== "" && !isValidSolanaAddress(e.target.value)) {
        setAddressErrorMessage("Invalid wallet address.");
      } else {
        setAddressErrorMessage("");
      }
    };



  return (
    <SideLayout>
      <div className="sectionMain">
        <div className="sectionHeader">
          <div className="leftSection">
            <h2>Referral</h2>
            <Button
              text="Add Referral"
              onClickHanlder={() => setShowAddModal(true)}
            ></Button>
          </div>
          <div className="rightSection">
            <div className="addressbox">
              <label htmlFor="Discount">Address:</label>
              <input
                placeholder="Enter user Address"
                type="text"
                value={search}
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className="sectionInnerMain">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">User Address</th>
                  <th scope="col">Referral Code</th>
                  <th scope="col">Referral Percentage</th>
                  <th scope="col">Referral Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {referralList?.list && referralList?.list?.length ? (
                  referralList?.list?.map((item: any, index: number) => (
                    <tr tabIndex={index} key={index}>
                      <td scope="row">{(page  -1 )* LIMIT + index + 1}</td>
                      <td>
                        <div
                          data-tooltip-id={item?.user_wallet_address}
                          data-tooltip-content={item?.user_wallet_address}
                          className="code"
                        >
                          {item?.user_wallet_address.length > 15
                            ? `${item?.user_wallet_address.slice(0, 15)}...`
                            : item?.user_wallet_address}
                          <Tooltip id={item?.user_wallet_address} />
                        </div>
                        <span
                          onClick={() => {
                            copyToClipboard(item?.user_wallet_address);
                            toastMsg.success("Copied to clipboard");
                          }}
                          title="Copy"
                        >
                          <img
                            src="/images/copy.svg"
                            alt="copy"
                            className="copyicon"
                            width={20}
                            height={20}
                          />
                        </span>
                      </td>
                      <td>
                        <div
                          data-tooltip-id={item?.referral_code}
                          data-tooltip-content={item?.referral_code}
                          className="code"
                        >
                          {item?.referral_code.length > 15
                            ? `${item?.referral_code.slice(0, 15)}...`
                            : item?.referral_code}
                          <Tooltip id={item?.referral_code} />
                        </div>
                        <span
                          onClick={() => {
                            copyToClipboard(
                              `${ACCESS_VBOT_TELEGRAM_LINK}${item?.referral_code}`
                            );
                            toastMsg.success("Copied to clipboard");
                          }}
                          title="Copy"
                        >
                          <img
                            src="/images/copy.svg"
                            alt="copy"
                            className="copyicon"
                            width={20}
                            height={20}
                          />
                        </span>
                      </td>
                      <td>{item?.referral_percentage}</td>
                      <td>{item?.active == 1 ? "Enabled" : "Disabled"}</td>
                      <td>
                        <div className="actionBtns">
                          <button
                            className={`edit ${item?.active == 0 ? "btn disabled" : ""}`}
                            onClick={() => {
                              setUserDetails(item);
                              setShowModal(true);
                            }}
                            disabled={item?.active == 0}
                          >
                            Edit
                          </button>
                          <button
                            className={`edit ${item?.active == 0 ? "btn-secondary" : "btn-secondary"}`} // add your class here accordingly
                            onClick={() => {
                              setIsCalledApi(true);
                              handleEnableDisableDeleteReferralFunction(item);
                            }}
                            disabled={isCalledApi == true}
                          >
                            {item?.active == 0 ? "Enable" : "Disable"}
                          </button>
                          <button
                            className={`edit ${item?.active == 0 ? "btn-danger" : "btn btn-danger"}`} // add your class here accordingly
                            onClick={() => {
                              setUserDetails(item);
                              setShowConfirmation(true);
                            }}
                          >
                            Delete
                          </button>
                          <button
                            className="detail btn-info"
                            role="button"
                            onClick={() =>
                              getSignleUserReferralHistoryFunction(
                                item?.user_wallet_address
                              )
                            }
                          >
                            Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} style={{ textAlign: "center" }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="container mt-5 d-flex justify-content-end">
            {referralList.totalItems > LIMIT && (
              <PaginationCommon
                totalPages={Math.ceil(referralList?.totalItems / LIMIT)}
                handleChange={handleChange}
              />
            )}
          </div>
        </div>
      </div>

      {showAddModal && (
        <AddReferralModel
          title="Create Referral Code"
          label='User Address'
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          handleConfrimApi={handleAddReferralCode}
          setIsCalledApi={setIsCalledApi}
          isCalledApi={isCalledApi}
          handleChangeInput={handleAddressInput}
          addressError={addressError}
          addressErrorMessage={addressErrorMessage}
          // handleChangeMaxUsedInput={()=>{}}
        />
      )}
      {showModal && (
        <AddPercentageModel
          title='Update Referral Percentage'
          label='Referral Percentage'
          showModal={showModal}
          setShowModal={setShowModal}
          setIsCalledApi={setIsCalledApi}
          isCalledApi={isCalledApi}
          setPercentageAmount={setPercentageAmount}
          handleAddPercentage={handleAddPercentage}
          percentageAmount={percentageAmount}
          userDetails={userDetails}
        />
      )}

      {
        showConfirmation &&
        <ConfirmationPopUp
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          setIsConfirm={setIsConfirm}
        />
      }
    </SideLayout>
  );
};

export default React.memo(Referral);
