import React from "react";
import { Helmet } from "react-helmet";
function PreloadImages() {
  return (
    <Helmet>
      <link
        rel="preload"
        href={"/images/solana-banner-image.webp"}
        as="image"
      />
      <link rel="preload" href={"/images/banner-mobile.webp"} as="image" />
    </Helmet>
  );
}

export default PreloadImages;
