import React, { useState } from "react";
// import { RightarrowIcon } from "../../assets/Svg/SvgImages";
import ReactPaginate from "react-paginate";

const PaginationCommon = ({  totalPages, handleChange }) => {
  return (
    <>
        <ReactPaginate
            className='Common_Table_Pagination'
          breakLabel="..."
          // initialPage={1}
          onPageChange={(e)=>handleChange(e)}
          // pageRangeDisplayed={2}
          pageCount={totalPages}
          // renderOnZeroPageCount={null}
        //   previousLabel={<RightarrowIcon />}
        //  nextLabel={<RightarrowIcon />}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        />
    </>
  );
};

export default PaginationCommon;
