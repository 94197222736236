import React, { useEffect, useState } from "react";
import SideLayout from "../pages/SideLayout";
import Button from "../../../components/Button";
import { ApiServices } from "../../../services/api.service";
import AddReferralModel from "../../../components/Modals/AddReferralCodeModel";
import AddPercentageModel from "../../../components/Modals/ReferralModel";
import "react-tooltip/dist/react-tooltip.css";
import {
  ADD_DISCOUNT_CODE,
  ApiResponse,
  DISCOUNT_ENABLE_DISABLE_DELETE,
  DISCOUNT_UPDATE_PERCENTAGE,
  GET_DISCOUNT_API_LIST,
} from "../../../services/apiSchema";
import { toastMsg } from "../../../components/Toast";
import ConfirmationPopUp from "../../../components/Modals/ConfirmationPopUp";
import PaginationCommon from "../../../components/PaginationCommon";
import { ACCESS_VBOT_TELEGRAM_LINK } from "../../../helpers/constants";
import { Tooltip } from "react-tooltip";
import { copyToClipboard } from "../../../helpers/utilites";

const Discount: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [isCalledApi, setIsCalledApi] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [maxUsed, setMaxUsed] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [discountList, setDiscountList] = useState({
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    list: [],
  });
  const LIMIT = 10;
  const [percentageAmount, setPercentageAmount] = useState(0);

  /**
   * Fetches the list of discounts from the server and updates the state with the response data.
   * If a search term is provided, it filters the discounts by the discount code.
   * On successful retrieval, updates the discount list state with pagination details.
   * Displays an error toast message if the API call fails.
   */
  const getDiscountList = async (): Promise<void> => {
    try {
      const data: GET_DISCOUNT_API_LIST = {
        page: page,
        limit: LIMIT,
        orderBy: "ASC", // optional
      };
      if (search !== "") {
        data.discountCode = search; // optional
      }
      const response = (await ApiServices.getListDiscountApi(
        data
      )) as ApiResponse;
      if (response.status === 200) {
        setDiscountList({
          currentPage: response?.data?.currentPage,
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          list: response?.data?.list,
        });
        console.log(response.data);
      }
    } catch (error: any) {
      toastMsg.error(error.data.message);
    }
  };

  useEffect(() => {
    // Avoiding multiple call while typing for search
    let timer: any;
    if (search) {
      timer = setTimeout(() => {
        getDiscountList();
        // setPage(1)
      }, 1000);
    } else {
      getDiscountList();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [page, search]);

  /**
   * This function handles the addition of a new discount code.
   * It constructs the discount code data with the current discount amount
   * and max usage, calls the API to add the discount code, and handles the response.
   * On success, it updates the discount list, closes the add modal, and shows a success message.
   * In case of an error, it displays an error message.
   * @returns {Promise<void>}
   */
  const handleAddDiscountCodeFunc = async (): Promise<void> => {
    try {
      const data: ADD_DISCOUNT_CODE = {
        percentage: discountAmount,
      };
      if (maxUsed !== 0) {
        data.maxUsed = maxUsed;
      }
      const response = (await ApiServices.addDiscountCodeApi(
        data
      )) as ApiResponse;
      if (response.status === 200) {
        toastMsg.success(response?.message);
        setIsCalledApi(false);
        getDiscountList();
        setShowAddModal(false);
      }
    } catch (error: any) {
      setIsCalledApi(false);
      toastMsg.error(error?.data?.message);
    }
  };

  /**
   * This function is used to update the search state when a search input is changed.
   * @param {object} e - The event object.
   * @return {void}
   */
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPage(1);
  };

  /**
   * Enable, disable, or delete a discount based on the provided item.
   * Constructs the discount data from the item and calls the API to update the discount status.
   * If `isConfirm` is true, the discount is deleted.
   * If `isConfirm` is false, the discount is enabled or disabled based on its current active state.
   * On success, updates the discount list and closes the confirmation modal.
   * Displays an error message in case of an API call failure.
   * @param {object} item - Discount object containing discount details.
   * @returns {Promise<void>}
   */

  const handleEnableDisableDeleteDiscountFunction = async (
    item: any
  ): Promise<void> => {
    console.log("item", item);
    try {
      const data: DISCOUNT_ENABLE_DISABLE_DELETE = {
        uUid: item?.u_uid,
        discountCode: item.discount_code,
        isEnable: item.active == 1 ? "false" : item.active == 0 ? "true" : "", // OPTIONAL
      };
      if (isConfirm === true) {
        data.isDelete = "true"; // OPTIONAL
      }
      const response = (await ApiServices.enableDisableDeleteDiscountApi(
        data
      )) as ApiResponse;
      if (response.status === 200) {
        setIsCalledApi(false);
        setShowConfirmation(false);
        getDiscountList();
      }
    } catch (error: any) {
      setIsCalledApi(false);
      toastMsg.error(error?.data?.message);
    }
  };

  useEffect(() => {
    if (isConfirm) {
      handleEnableDisableDeleteDiscountFunction(userDetails);
    }
  }, [isConfirm]);

  /**
   * Update discount percentage.
   * If `percentageAmount` is valid number, then it updates the discount percentage.
   * If `percentageAmount` is not valid number, then it doesn't update the discount percentage.
   * If `maxUsed` is valid number, then it updates the discount max used count.
   * If `maxUsed` is not valid number, then it doesn't update the discount max used count.
   * @returns {Promise<void>}
   */
  const handleAddPercentage = async (): Promise<void> => {
    try {
      const data: DISCOUNT_UPDATE_PERCENTAGE = {
        discountCode: userDetails.discount_code, // REQUIRED
        uUid: userDetails.u_uid, // REQUIRED
        value: !Number.isNaN(percentageAmount)
          ? percentageAmount
          : userDetails.discount_percentage, // OPTIONAL
        maxUsed: !Number.isNaN(maxUsed)
          ? maxUsed
          : userDetails.discount_max_used_count,
      };

      const response = (await ApiServices.discountUpdatePercentageApi(
        data
      )) as ApiResponse;
      if (response.status === 200) {
        getDiscountList();
        setShowModal(false);
        setIsCalledApi(false);
        toastMsg.success(response?.message);
      }
    } catch (error: any) {
      setIsCalledApi(false);
      toastMsg.error(error?.data?.message);
    }
  };

  /**
   * Handles the input change for the discount percentage input field in the add discount modal.
   * If the input value is a valid number with at most 2 decimal places, then it updates the discount amount state with the new value.
   * If the input value is not a valid number, then it doesn't update the discount amount state.
   * @param {React.ChangeEvent<HTMLInputElement>} e The input event.
   */
  const handleAddressInput = (e: any) => {
    if (/^\d*(\.\d{0,2})?$/.test(e.target.value)) {
      const newValue = Number(e.target.value);
      setDiscountAmount(newValue);
      setIsCalledApi(false);
    }
  };

  /**
   * Handles the input change for the max used input field in the discount update percentage modal.
   * If the input value is a valid number, then it updates the max used state with the new value.
   * If the input value is not a valid number, then it doesn't update the max used state.
   * @param {React.ChangeEvent<HTMLInputElement>} e The input event.
   */
  const handleChangeMaxUsedInput = (e: any) => {
    if (/^$|([1-9]\d*)$/.test(e.target.value)) {
      setMaxUsed(e.target.value);
      setIsCalledApi(false);
    }
  };

  // ::::::: handle paganition change :::::::
  const handleChange = (e: any) => {
    setPage(e.selected + 1);
  };

  return (
    <SideLayout>
      <div className="sectionMain">
        <div className="sectionHeader">
          <div className="leftSection">
            <h2>Discount</h2>
            <Button
              text="Add Discount Code"
              onClickHanlder={() => setShowAddModal(true)}
            ></Button>
          </div>
          <div className="rightSection">
            <div className="addressbox">
              <label htmlFor="Discount">Discount:</label>
              <input
                placeholder="Enter discount code"
                type="text"
                value={search}
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className="sectionInnerMain">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">Discount Code</th>
                  <th scope="col">Discount Percentage</th>
                  <th scope="col">Max Used</th>
                  <th scope="col">Discount Used Count</th>
                  <th scope="col">Discount Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {discountList.list && discountList.list.length > 0
                  ? discountList.list.map((item: any, index: number) => (
                      <tr tabIndex={index} key={index}>
                        <td scope="row">{(page - 1) * LIMIT + index + 1}</td>
                        <td>
                          <div
                            data-tooltip-id={item?.discount_code}
                            data-tooltip-content={item?.discount_code}
                            className="code"
                          >
                            {item?.discount_code.length > 15
                              ? `${item?.discount_code.slice(0, 15)}...`
                              : item?.discount_code}
                            <Tooltip id={item?.discount_code} />
                          </div>
                          <span
                            onClick={() => {
                              copyToClipboard(
                                `${ACCESS_VBOT_TELEGRAM_LINK}${item?.discount_code}`
                              );
                              toastMsg.success("Copied to clipboard");
                            }}
                            title="Copy"
                          >
                            <img
                              src="/images/copy.svg"
                              alt="copy"
                              className="copyicon"
                              width={20}
                              height={20}
                            />
                          </span>
                        </td>
                        <td>{item.discount_percentage}</td>
                        <td>{item.discount_max_used_count}</td>
                        <td>{item.discount_used_count}</td>
                        <td>{item?.active == 1 ? "Enabled" : "Disabled"}</td>
                        <td>
                          <div className="actionBtns">
                            <button
                              className={`edit ${item?.active == 0 ? "btn disabled" : ""}`}
                              onClick={() => {
                                setUserDetails(item);
                                setShowModal(true);
                              }}
                              disabled={item?.active == 0}
                            >
                              Edit
                            </button>
                            <button
                              className={`edit ${item?.active == 0 ? "btn-secondary" : "btn-secondary"}`} // add your class here accordingly
                              onClick={() => {
                                setIsCalledApi(true);
                                handleEnableDisableDeleteDiscountFunction(item);
                              }}
                              disabled={isCalledApi == true}
                            >
                              {item?.active == 0 ? "Enable" : "Disable"}
                            </button>
                            <button
                              className={`edit ${item?.active == 0 ? "btn-danger" : "btn btn-danger"}`} // add your class here accordingly
                              onClick={() => {
                                setUserDetails(item);
                                setShowConfirmation(true);
                              }}
                            >
                              Delete
                            </button>
                            {/* <button
                            className="detail btn-info"
                            role="button"
                            // onClick={() =>
                            //   // getSignleUserReferralHistoryFunction(
                            //   //   item?.user_wallet_address
                            //   // )
                            // }
                          >
                            Details
                          </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
          <div className="container mt-5 d-flex justify-content-end">
            {discountList.totalItems > LIMIT && (
              <PaginationCommon
                totalPages={Math.ceil(discountList?.totalItems / LIMIT)}
                handleChange={handleChange}
              />
            )}
          </div>
        </div>
      </div>
      {showAddModal && (
        <AddReferralModel
          title="Add Discount Code"
          label="Discount Percentage ( % )"
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          handleConfrimApi={handleAddDiscountCodeFunc}
          setIsCalledApi={setIsCalledApi}
          isCalledApi={isCalledApi}
          handleChangeInput={handleAddressInput}
          isDiscount={true}
          handleChangeMaxUsedInput={handleChangeMaxUsedInput}
        />
      )}
      {showModal && (
        <AddPercentageModel
          title="Update Discount Percentage"
          label="Discount Percentage"
          showModal={showModal}
          setShowModal={setShowModal}
          setIsCalledApi={setIsCalledApi}
          isCalledApi={isCalledApi}
          setPercentageAmount={setPercentageAmount}
          percentageAmount={percentageAmount}
          handleAddPercentage={handleAddPercentage}
          userDetails={userDetails}
          isDiscount={true}
          handleChangeMaxUsedInput={handleChangeMaxUsedInput}
          setMaxUsed={setMaxUsed}
          maxUsed={maxUsed}
        />
      )}
      {showConfirmation && (
        <ConfirmationPopUp
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          setIsConfirm={setIsConfirm}
        />
      )}
    </SideLayout>
  );
};

export default Discount;
