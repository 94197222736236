import copy from "copy-to-clipboard";
import {
    PublicKey
} from '@solana/web3.js';


/**
 * Copies the provided value to the user's clipboard.
 *
 * @param {string} value - The value to be copied to the clipboard.
 * @return {void}
 */
export const copyToClipboard = (value) => {
  copy(value);
};


export const throtteling = (fn, delay) => {
  let timer;
  console.log('ssasdfadsf',fn,delay)
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}




/**
 * Checks whether the given string is a valid Solana address.
 *
 * @param {string} address - The address to check.
 * @return {boolean} true if the address is valid, false otherwise.
 */
export function isValidSolanaAddress(address: string) {
    try {
        let publicKey = new PublicKey(address);
        return PublicKey.isOnCurve(publicKey.toBuffer());
    } catch (e) {
        return false;
    }
}