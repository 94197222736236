import React from "react";
import SideLayout from "./SideLayout";
const Dashboard = () => {
  return (
    <>
      <SideLayout>
        <h1>Dashboard</h1>
      </SideLayout>
    </>
  );
};

export default Dashboard;
