import axios from "axios";

export { _fetch as fetch };

/**
 * @private
 * @function handleResponse
 * @description Resolves the promise with the server response.
 * @param {object} response - The response from the server.
 * @param {function} resolve - The function to call to resolve the promise.
 */
const handleResponse = (response, resolve) => {
  resolve(response);
  return;
};

/**
 * @private
 * @function handleError
 * @description Handles errors that are received from a fetch call.
 * @param {object} error - The error that was received.
 * @param {function} reject - The reject function of a promise.
 * @returns {undefined}
 */
const handleError = (error, reject) => {
  if (error) {
    console.log({ error });
  }
  if (error && error.status === 401) {
    localStorage.clear();
    window.location.reload();
    return;
  }

  reject(error);
  return;
};

/**
 * Configures and sends an HTTP request using axios.
 *
 * @param {string} method - The HTTP method to use (GET, POST, PUT, DELETE).
 * @param {string} path - The API endpoint path.
 * @param {object} body - The request payload for POST or PUT requests.
 * @param {object} options - Additional request options (e.g., JWT for authorization).
 * @param {object} params - Query parameters to append to the URL.
 * @returns {Promise} - A promise that resolves with the server response.
 */

const setMethod = (method, path, body, options, params) => {
  // Configure axios: Means if we need to pass jwt token as header not as cookies we are using this part
  let config;
  if (options) {
    if (options.jwt) {
      config.headers = {
        "api-access-token": options.jwt,
      };
    }
  }
  params = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";

  if (method === "get" || method === "delete") {
    return axios[method](`${path}${params}`, config);
  }
  if (method === "post" || method === "put") {
    return axios[method](`${path}`, body, config);
  }
};

/**
 * @private
 * @function _fetch
 * @description Makes a promise-based api call to the server.
 * @param {string} method - The http method to use.
 * @param {string} path - The path to the api endpoint.
 * @param {object} body - The body of the request.
 * @param {object} options - The options for the request.
 * @param {object} params - The query parameters for the request.
 * @returns {Promise} - A promise that resolves with the server response.
 */
function _fetch(method, path, body, options, params) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await setMethod(method, path, body, options, params);
      handleResponse(response, resolve);
      return;
    } catch (error) {
      handleError(error.response, reject);
      return;
    }
  });
}
