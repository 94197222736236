import React from "react";
import "../index.scss";
import "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js";
import { useAuth } from "../auth/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { ToastContainer } from "react-toastify"; 
import { NavLink } from "react-router-dom";



const SideLayout = ({ children }: any) => {
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const { state } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="dashbaord">
      <div className="panelHeader">
        <div className="user dropdown">
          <span className="userinfo">Howdy, {state.user}</span>
          <NavLink
            to="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="/images/user.svg" alt="" />
          </NavLink>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a className="dropdown-item" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mainBody">
        <div className="sidebar">
          <div className="logo">
            <img src="/images/3d-logo.webp" alt="logo" />
          </div>
          <div className="menu">
            <ul className="navbar-nav">
              <li
                className={`nav-item ${isActive("/dashboard/referral") ? " active" : ""}`}
              >
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/dashboard/referral"
                >
                  Referral
                </NavLink>
              </li>

               <li
                className={`nav-item ${isActive("/dashboard/discount") ? " active" : ""}`}
              >
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/dashboard/discount"
                >
                  Discount
                </NavLink>
              </li>             
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/dashbaord/referral"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Referral
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      10% Discount
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      15% Discount
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      20% Discount
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="main-panel">
          <div className="panelContent">{children}</div>
        </div>
      </div>
       <ToastContainer />
    </div>
  );
};

export default SideLayout;
